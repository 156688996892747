<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="500" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" class="query-form" :rules="formRules" :label-col="{span:5}" :wrapper-col="{span:15}">
        <a-form-model-item label="模板名称" prop="templatename">
          <a-input v-model.trim="queryParams.templatename" placeholder="请输入" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item label="终端绑定" prop="device">
          <a-button type="primary" @click="selectdeviceVisible=true">选择</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table v-if="showDeviceTable" :columns="tableColumns" :data-source="deviceList" row-key="deviceinfoid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 10px;" :pagination="false">
        <span slot="terminaltype" slot-scope="text">
          <span class="text">{{terminaltypesMap[text]||''}}</span>
        </span>
        <span slot="isonline" slot-scope="value">
        <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
      </span>
      </a-table>
    </div>
    <select-device :visible.sync="selectdeviceVisible" :default-selected="deviceList" :formData="queryParams" @get-selected-rows="getDeviceList"></select-device>
  </a-modal>
</template>
<script>

import {mapState} from "vuex";
import {addSceneTemplate, getSceneTemplateById, modifySceneTemplate} from "A/jcgn";
import SelectDevice from "C/modals/SelectDevice";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  components:{
    SelectDevice
  },
  data() {
    return {
      modalVisible: false,
      equipmentTypes:[],
      queryParams:{
        scenetemplateid:'',
        monitorpointnum:'',
        templatename:'',
        scenetype:1,
      },
      formRules: {
        templatename: [{required: true, message: '请输入模板名称'}],
      },
      tableColumns: [
        {
          title: '监控设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '监控设备ID',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      selectdeviceVisible:false,
      showDeviceTable: true,
      deviceList:null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail()
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    deviceList(val) {
      if(val && val.length) {
        this.queryParams.deviceid = val.map(item => item.deviceinfoid).join('|');
        this.queryParams.deviceinfoid= val.map(item => item.deviceinfoid).join('|');
      }else {
        this.queryParams.deviceid = '';
        this.queryParams.deviceinfoid='';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          let params = {
            scenetemplateid: this.detailData.scenetemplateid
          }
          getSceneTemplateById(params).then(res=>{
            if (res && res.returncode == '0') {
              for(let key in this.queryParams) {
                this.queryParams[key] = this.detailData[key]||'';
              }
              if (res.item.deviceinfos && res.item.deviceinfos.length > 0) {
                this.queryParams.deviceinfoid = res.item.deviceinfos[0].deviceinfoid
                this.queryParams.devicenum = res.item.deviceinfos[0].devicenum
                this.queryParams.devicename = res.item.deviceinfos[0].devicename
              }
              if (res.item.deviceid == 0) {
                res.item.deviceid = '';
                this.formDatas.deviceinfoid = '';
              }
              if (res.item.deviceinfos && res.item.deviceinfos.length) {
                this.deviceList = res.item.deviceinfos.map(item => {
                  item.deviceinfoid = '' + item.deviceinfoid;
                  return item;
                });
              }
            }
          })
        })
      }
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addSceneTemplate(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifySceneTemplate(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    getDeviceList(rows) {
      this.deviceList = rows;
      this.queryParams.deviceinfos=[]
      for(let i=0;i<rows.length;i++){
        this.queryParams.deviceinfos.push({
          deviceinfoid:rows[i].deviceinfoid,
          devicenum:rows[i].devicenum,
          devicename:rows[i].devicename,
        })
      }
    },
  }
}
</script>